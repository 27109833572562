import { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserType } from "../../Types/UserType";
import { getAccesstoken } from "../../utils/getAccesstoken";
import { getUser } from "../../utils/getUser";

const Header = () => {
  const [user, setUser] = useState<UserType | null>(null);
  const accesstoken = getAccesstoken();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!accesstoken) {
      return;
    }
    const fetchUser = async () => {
      const userData = await getUser(accesstoken);
      setUser(userData?.user);
    };

    fetchUser();
  }, [accesstoken]);

  const logout = () => {
    localStorage.removeItem("access_token");
    setUser(null);
    navigate("/sign-in");
  };
  console.log(location.pathname);

  return (
    <header className=" bg-gray-100 text-black shadow-md">
      <div className="custom_container flex flex-col md:flex-row justify-between items-center p-4">
        <div className="text-2xl font-bold text-center">
          <Link to="/" className="flex flex-col items-center">
            <img
              src="/logo.png"
              alt=""
              className="h-10"
            />
            {/* <span className="text-sm font-mono mt-1">Karentpay Checker</span> */}
          </Link>
        </div>
        <nav className="mt-5 md:mt-0">
          <ul className="flex flex-wrap justify-center items-center gap-2">
            {user?.id ? (
              <>
                <li className={` border-gray-900 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-700 active:bg-gray-800 text-white hover:scale-105 transition-transform duration-300 ${location.pathname === '/keys' ? 'bg-gray-900' : 'bg-gray-600'}`}>
                  <Link to="/keys">Keys</Link>
                </li>
                <li className={` border-gray-900 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-700 active:bg-gray-800 text-white hover:scale-105 transition-transform duration-300 ${location.pathname === '/withdraws' ? 'bg-gray-900' : 'bg-gray-600'}`}>
                  <Link to="/withdraws">Withdraw</Link>
                </li>
                <li className={` border-gray-900 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-700 active:bg-gray-800 text-white hover:scale-105 transition-transform duration-300 ${location.pathname === '/deposits-direct' ? 'bg-gray-900' : 'bg-gray-600'}`}>
                  <Link to="/deposits-direct">Direct Deposit</Link>
                </li>
                <li className={` border-gray-900 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-700 active:bg-gray-800 text-white hover:scale-105 transition-transform duration-300 ${location.pathname === '/deposits' ? 'bg-gray-900' : 'bg-gray-600'}`}>
                  <Link to="/deposits">Deposit</Link>
                </li>
                <li
                  className=" border-gray-900 bg-gray-600 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-700 active:bg-gray-800 text-white hover:scale-105 transition-transform duration-300 "
                  onClick={() => logout()}
                >
                  Sign Out
                </li>
              </>
            ) : (
              <>
                <li>
                  <Link
                    to="/sign-in"
                    className="bg-gray-600 border-gray-900 px-2 py-1 rounded-md cursor-pointer hover:bg-gray-700 active:bg-gray-800 text-white hover:scale-105 transtion-transform duration-300 "
                  >
                    Sign In
                  </Link>
                </li>
              </>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
