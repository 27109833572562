import { FaFacebook, FaYoutube, FaWhatsapp } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-gray-800  text-white p-4 py-6">
      {/* Main container with responsive flex layout */}
      <div className="flex flex-col sm:flex-row justify-between items-center  mx-auto custom_container ">
        
        {/* Social media and copyright section */}
        <div className="w-full sm:w-auto mb-4 sm:mb-0">
          <div className="flex gap-4 justify-center sm:justify-start mb-3 flex-wrap">
            <a href="https://www.facebook.com/bangladeshisoft" 
               className="flex items-center gap-2 px-3 py-1.5 rounded-sm
               hover:bg-blue-600 bg-blue-500 active:bg-blue-700 transition-colors">
              <FaFacebook className="text-xl" /> Facebook
            </a>
            <a href="https://www.youtube.com/bangladeshisoftware"
               className="flex items-center gap-2 px-3 py-1.5 rounded-sm
               hover:bg-red-600 bg-red-500 active:bg-red-700 transition-colors">
              <FaYoutube className="text-xl" /> YouTube
            </a>
            <a href="https://api.whatsapp.com/send?phone=+8801719182586&text=Hi,%20is%20anyone%20available%20to%20chat?"
               className="flex items-center gap-2 px-3 py-1.5 rounded-sm 
               hover:bg-green-500 bg-green-500 active:bg-green-600 transition-colors">
              <FaWhatsapp className="text-xl" /> WhatsApp
            </a>
          </div>
          <p className="text-sm text-center sm:text-left">
            &copy; {new Date().getFullYear()} Karentpay By Bangladesh Software. All rights reserved.
          </p>
        </div>

        {/* Payment options section */}
        <div className="w-full sm:w-auto text-center sm:text-right">
          <h1 className="text-xl font-bold mb-2 w-full text-center sm:text-left ">Payment Options:</h1>
          <img src="/paymentimg.webp" alt="Payment Options" className="inline-block"  sizes="100px" width={400} height={100} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
